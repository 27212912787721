import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '../tools/withStyles';
import { Main } from '../components/Main';
import { Link } from '../components/Link';
import { Text } from '../components/Text';
import { Fader } from '../components/Fader';
import { Secuence } from '../components/Secuence';
import axios from 'axios'
import './playground.css'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const styles = theme => ({
  root: {}
});

const IconFeedMute = MakeIcon(
  <path d="M18 9.5c2.481 0 4.5 1.571 4.5 3.503 0 1.674-1.703 3.48-4.454 3.48-.899 0-1.454-.156-2.281-.357-.584.358-.679.445-1.339.686.127-.646.101-.924.081-1.56-.583-.697-1.007-1.241-1.007-2.249 0-1.932 2.019-3.503 4.5-3.503zm0-1.5c-3.169 0-6 2.113-6 5.003 0 1.025.37 2.032 1.023 2.812.027.916-.511 2.228-.997 3.184 1.302-.234 3.15-.754 3.989-1.268.709.173 1.388.252 2.03.252 3.542 0 5.954-2.418 5.954-4.98.001-2.906-2.85-5.003-5.999-5.003zm-.668 6.5h-1.719v-.369l.938-1.361v-.008h-.869v-.512h1.618v.396l-.918 1.341v.008h.95v.505zm3.035 0h-2.392v-.505l1.306-1.784v-.011h-1.283v-.7h2.25v.538l-1.203 1.755v.012h1.322v.695zm-10.338 9.5c1.578 0 2.971-1.402 2.971-3h-6c0 1.598 1.45 3 3.029 3zm.918-7.655c-.615-1.001-.947-2.159-.947-3.342 0-3.018 2.197-5.589 5.261-6.571-.472-1.025-1.123-1.905-2.124-2.486-.644-.374-1.041-1.07-1.04-1.82v-.003c0-1.173-.939-2.123-2.097-2.123s-2.097.95-2.097 2.122v.003c.001.751-.396 1.446-1.041 1.82-4.667 2.712-1.985 11.715-6.862 13.306v1.749h9.782c.425-.834.931-1.764 1.165-2.655zm-.947-15.345c.552 0 1 .449 1 1 0 .552-.448 1-1 1s-1-.448-1-1c0-.551.448-1 1-1z" />
);

const IconFeedSettings = MakeIcon(
  <path d="M6 16h-6v-3h6v3zm-2-5v-10h-2v10h2zm-2 7v5h2v-5h-2zm13-7h-6v-3h6v3zm-2-5v-5h-2v5h2zm-2 7v10h2v-10h-2zm13 3h-6v-3h6v3zm-2-5v-10h-2v10h2zm-2 7v5h2v-5h-2z" />
);

const IconMenuMore = MakeIcon(
  <path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z" />
);

const IconFeedAdd = MakeIcon(
  <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
);

const IconSearchSubmit = MakeIcon(
  <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
);

const IconShop = MakeIcon(
  <path d="M16.53 7l-.564 2h-15.127l-.839-2h16.53zm-14.013 6h12.319l.564-2h-13.722l.839 2zm5.983 5c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm11.305-15l-3.432 12h-13.017l.839 2h13.659l3.474-12h1.929l.743-2h-4.195zm-6.305 15c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z" />
);

const FIXTURES = {
  headerMenu: [
    { notificationCount: 0, text: "Device" },
    { isActive: true, notificationCount: 11, text: "Notifications" },
    { notificationCount: 0, text: "Sensors" },
    { notificationCount: 0, text: "Analysed Data" },
    { notificationCount: 0, text: "Files" }
  ],
  feed: [
    { id: "5ba5", name: "nVidia", unread: 3 },
    { id: "4f22", name: "RP4 #1" },
    { id: "fee9", name: "RP4 #2" },
    { id: "a0cc", name: "RP3 #1" },
    { id: "dee3", name: "RP3 #2", isPrivate: true, unread: 2 }
  ],
  conversation: [
    {
      id: "cc23",
      isOnline: true,
      unread: 5,
      name: "Face Recognition"
    },
    { id: "95b4", isOnline: true, name: "Age / Gender detection", unread: 1 },
    { id: "10cf", name: "Emotion detection" },
    { id: "e466", name: "Environment sensors" },
  ],
  messages: [
    {
      id: "fd0cf",
      content:
        "Device targeted new person. Age between 25-32, male, happy.",
      dateTime: "2077-10-09T11:04:57Z",
      author: {
        id: "d12c",
        name: "V.M. Vargas"
      }
    }
  ]
};

function NavSection({ children, renderTitle }) {
  return (
    <div className="nav-section">
      <div className="nav-section__header">
        {renderTitle({ className: "nav-section__title" })}
      </div>
      <div className="nav-section__body">{children}</div>
    </div>
  );
}

function FeedMessage({ message }) {
  return (
    <div className="message">
      <div className="message__body">
        <div>
          {
            "Test failed. Device is not connected."
          }
        </div>
      </div>
      <div className="message__footer">
        <span className="message__authoring">U.A.G.I. - Horizon</span>
        {" - 11:04pm"}
      </div>
    </div>
  );
}

function ChannelNav({ activeChannel = null, channels = [] }) {
  return (
    <ul className="nav">
      {channels.map((channel) => (
        <li className="nav__item">
          <a
            className={`nav__link ${
              activeChannel && activeChannel.id === channel.id
                ? "nav__link--active"
                : ""
            }`}
            href="#"
          >
            <ChannelLink {...channel}>NAME</ChannelLink>
          </a>
        </li>
      ))}
    </ul>
  );
}

function ConversationNav({ activeConversation = null, conversations = [] }) {
  return (
    <ul className="nav">
      {conversations.map((convo) => (
        <li className="nav__item">
          <a
            className={`nav__link ${
              activeConversation && activeConversation.id === convo.id
                ? "nav__link--active"
                : ""
            }`}
            href="#"
          >
            <ConversationLink conversation={convo} />
          </a>
        </li>
      ))}
    </ul>
  );
}

function ChannelLink({ icon, name, unread }) {
  return (
    <span
      className={`channel-link ${
        unread > 0 ? "conversation-link--unread" : ""
      }`}
    >
      <span className="channel-link__icon">#</span>
      <span className="channel-link__element">{name}</span>

      {unread > 0 && (
        <span className="channel-link__element">
          <Badge>{unread}</Badge>
        </span>
      )}
    </span>
  );
}

function ConversationLink({ conversation }) {
  return (
    <span
      className={`conversation-link ${
        conversation.isOnline ? "conversation-link--online" : ""
      } ${conversation.unread > 0 ? "conversation-link--unread" : ""}`}
    >
      {conversation.members && conversation.members.length > 2 ? (
        <span className="conversation-link__icon" />
      ) : (
        <span className="conversation-link__icon" />
      )}

      <span className="conversation-link__element">{conversation.name}</span>

      {conversation.unread > 0 && (
        <span className="conversation-link__element">
          <Badge>{conversation.unread}</Badge>
        </span>
      )}
    </span>
  );
}

function Badge({ children }) {
  return <span className="badge">{children}</span>;
}

function Button({
                  children,
                  type = "button",
                  size = "default",
                  variant = "default"
                }) {
  return (
    <button
      className={`button ${variant ? `button--${variant}` : ""} ${
        size ? `button--size-${size}` : ""
      }`}
      type={type}
    >
      <span className="button__content">{children}</span>
    </button>
  );
}

function Pad({ children, renderCap = null }) {
  return (
    <div className="pad">
      <div className="pad__body">{children}</div>
    </div>
  );
}

function NavItem({ navItem }) {
  return (
    <li className="nav__item">
      <a
        className={`nav__link ${navItem.isActive ? "nav__link--active" : ""}`}
        href="#"
      >
        <span className="nav__link__element">{navItem.text}</span>
        {navItem.notificationCount > 0 && (
          <span className="nav__link__element">
            <Badge>{navItem.notificationCount}</Badge>
          </span>
        )}
      </a>
    </li>
  );
}

function MakeTextBase(classNameDefault, $asDefault) {
  return ({ $as = null, children, className }) => {
    const AsComponent = $as || $asDefault;

    return (
      <AsComponent className={`${classNameDefault} ${className}`}>
        {children}
      </AsComponent>
    );
  };
}

const TextHeading1 = MakeTextBase("text-heading1", "h1");
const TextHeading2 = MakeTextBase("text-heading2", "h2");
const TextHeading3 = MakeTextBase("text-heading3", "h3");
const TextHeading4 = MakeTextBase("text-heading4", "h4");
const TextHeading5 = MakeTextBase("text-heading5", "h5");
const TextHeading6 = MakeTextBase("text-heading6", "h6");
const TextParagraph1 = MakeTextBase("text-paragraph1", "p");
const TextOverline = MakeTextBase("segment-topbar__overline", "span");

function MakeIcon(svg) {
  return ({ className }) => (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {svg}
    </svg>
  );
}

class Playground extends React.Component {
  static propTypes = {
    classes: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }

  onEmailChange(event) {
    this.setState({email: event.target.value})
  }

  onMessageChange(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://localhost:3002/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: "", email: "", message: ""})
  }

  render () {
    const { classes } = this.props;

    return (
      <Main className={classes.root}>
        <Secuence stagger>



      <div className="app-skeleton">
        <header className="app-header">
          <div className="app-header__anchor">
            <span className="app-header__anchor__text">U.A.G.I. - Horizon</span>
          </div>
          <nav>
            <ul className="nav">
              {FIXTURES.headerMenu.map((navItem, navItemIndex) => (
                <NavItem key={navItemIndex} navItem={navItem} />
              ))}
            </ul>
          </nav>
          <div />
        </header>
        <div className="app-container">
          <div className="app-a">
            <div className="segment-topbar">
              <div className="segment-topbar__header">
                <TextHeading3 className="segment-topbar__title">
                  Admin Dashboard
                </TextHeading3>
              </div>
              <div className="segment-topbar__aside">
                <div className="button-toolbar">
                  <a className="button button--primary button--size-lg">
                    <IconFeedAdd className="button__icon" />
                  </a>
                </div>
              </div>
            </div>

            <form className="form-search" onSubmit={(e) => e.preventDefault()}>
              <div className="form-group">
                <div className="form-control form-control--with-addon">
                  <input name="query" placeholder="Search..." type="text" />
                  <div className="form-control__addon form-control__addon--prefix">
                    <IconSearchSubmit />
                  </div>
                </div>
              </div>
            </form>

            <NavSection renderTitle={(props) => <h2 {...props}>Device Boards</h2>}>
              <ChannelNav
                activeChannel={{ id: "a0cc", name: "Watson" }}
                channels={FIXTURES.feed}
              />
            </NavSection>

            <NavSection renderTitle={(props) => <h2 {...props}>Activated Analysis</h2>}>
              <ConversationNav conversations={FIXTURES.conversation} />
            </NavSection>
          </div>
          <div className="app-main">
            <div className="channel-feed">
              <div className="camera-container" style={{ height: '420px' }}>
                {/* <video id="myVidPlayer" controls muted autoPlay></video> */}
                <div className="box-1 box"></div>
                <div className="box-2 box">
                  <div className="conditions"></div>
                  <div className="svg-box">
                    <svg
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 912.042 122.683"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect width="488" height="9" fill="none" />
                        </clipPath>
                      </defs>
                      <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M4179.589,66,4204.7,92.843h299.127L4527.574,66"
                        transform="translate(-3897.56 -65.317)"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      />
                      <g
                        id="Repeat_Grid_16"
                        data-name="Repeat Grid 16"
                        transform="translate(212.021 113.683)"
                        clipPath="url(#clipPath)"
                      >
                        <g transform="translate(-74 -81)">
                          <g
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-62 -81)">
                          <g
                            id="Rectangle_14-2"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-50 -81)">
                          <g
                            id="Rectangle_14-3"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-38 -81)">
                          <g
                            id="Rectangle_14-4"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-26 -81)">
                          <g
                            id="Rectangle_14-5"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-14 -81)">
                          <g
                            id="Rectangle_14-6"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-2 -81)">
                          <g
                            id="Rectangle_14-7"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(10 -81)">
                          <g
                            id="Rectangle_14-8"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(22 -81)">
                          <g
                            id="Rectangle_14-9"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(34 -81)">
                          <g
                            id="Rectangle_14-10"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(46 -81)">
                          <g
                            id="Rectangle_14-11"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(58 -81)">
                          <g
                            id="Rectangle_14-12"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(70 -81)">
                          <g
                            id="Rectangle_14-13"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(82 -81)">
                          <g
                            id="Rectangle_14-14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(94 -81)">
                          <g
                            id="Rectangle_14-15"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(106 -81)">
                          <g
                            id="Rectangle_14-16"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(118 -81)">
                          <g
                            id="Rectangle_14-17"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(130 -81)">
                          <g
                            id="Rectangle_14-18"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(142 -81)">
                          <g
                            id="Rectangle_14-19"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(154 -81)">
                          <g
                            id="Rectangle_14-20"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(166 -81)">
                          <g
                            id="Rectangle_14-21"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(178 -81)">
                          <g
                            id="Rectangle_14-22"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(190 -81)">
                          <g
                            id="Rectangle_14-23"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(202 -81)">
                          <g
                            id="Rectangle_14-24"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(214 -81)">
                          <g
                            id="Rectangle_14-25"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(226 -81)">
                          <g
                            id="Rectangle_14-26"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(238 -81)">
                          <g
                            id="Rectangle_14-27"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(250 -81)">
                          <g
                            id="Rectangle_14-28"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(262 -81)">
                          <g
                            id="Rectangle_14-29"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(274 -81)">
                          <g
                            id="Rectangle_14-30"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(286 -81)">
                          <g
                            id="Rectangle_14-31"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(298 -81)">
                          <g
                            id="Rectangle_14-32"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(310 -81)">
                          <g
                            id="Rectangle_14-33"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(322 -81)">
                          <g
                            id="Rectangle_14-34"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(334 -81)">
                          <g
                            id="Rectangle_14-35"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(346 -81)">
                          <g
                            id="Rectangle_14-36"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(358 -81)">
                          <g
                            id="Rectangle_14-37"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(370 -81)">
                          <g
                            id="Rectangle_14-38"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(382 -81)">
                          <g
                            id="Rectangle_14-39"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(394 -81)">
                          <g
                            id="Rectangle_14-40"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(406 -81)">
                          <g
                            id="Rectangle_14-41"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_20"
                        data-name="Group 20"
                        transform="translate(-3943.516 -1107.16)"
                      >
                        <g
                          id="Group_19"
                          data-name="Group 19"
                          transform="translate(3944.516 1177)"
                        >
                          <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M4341.613,944.571H3913.958V970"
                            transform="translate(-3913.958 -944.571)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M3913.958,944.571h426.822V970"
                            transform="translate(-3430.737 -944.571)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                          />
                        </g>
                        <path
                          id="Path_42"
                          data-name="Path 42"
                          d="M3925.8,984.085h300.3l15,11.927h100.363"
                          transform="translate(29.537 200.783)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_41"
                          data-name="Path 41"
                          d="M4341.462,984.085h-300.3l-15,11.927H3925.8"
                          transform="translate(503.274 200.783)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="box-3 box"></div>
                <div className="box-4 box">
                  <div className="box-4-svg">
                    <svg
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 54.527 349.464"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect width="307.992" height="9" fill="none" />
                        </clipPath>
                      </defs>
                      <g
                        id="Repeat_Grid_15"
                        data-name="Repeat Grid 15"
                        transform="translate(54.527 30.727) rotate(90)"
                        clipPath="url(#clipPath)"
                      >
                        <g transform="translate(-74 -81)">
                          <g
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-38 -81)">
                          <g
                            id="Rectangle_14-2"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-2 -81)">
                          <g
                            id="Rectangle_14-3"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(34 -81)">
                          <g
                            id="Rectangle_14-4"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(70 -81)">
                          <g
                            id="Rectangle_14-5"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(106 -81)">
                          <g
                            id="Rectangle_14-6"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(142 -81)">
                          <g
                            id="Rectangle_14-7"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(178 -81)">
                          <g
                            id="Rectangle_14-8"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(214 -81)">
                          <g
                            id="Rectangle_14-9"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                      </g>
                      <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M4179.589,92.843,4204.7,66h299.127l23.751,26.843"
                        transform="translate(93.526 -4178.858) rotate(90)"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="box-5 box">
                  <div className="circle-nav">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="-100 -100 700 700"
                      id="menu"
                    >
                      <g id="symbolsContainer">
                        <symbol className="icon icon-" id="icon-1" viewBox="0 0 40 40">
                          <path d="M32 18.451l-16-12.42-16 12.42v-5.064l16-12.42 16 12.42zM28 18v12h-8v-8h-8v8h-8v-12l12-9z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-2" viewBox="0 0 40 40">
                          <path d="M34 4h-2v-2c0-1.1-0.9-2-2-2h-28c-1.1 0-2 0.9-2 2v24c0 1.1 0.9 2 2 2h2v2c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-24c0-1.1-0.9-2-2-2zM4 6v20h-1.996c-0.001-0.001-0.003-0.002-0.004-0.004v-23.993c0.001-0.001 0.002-0.003 0.004-0.004h27.993c0.001 0.001 0.003 0.002 0.004 0.004v1.996h-24c-1.1 0-2 0.9-2 2v0zM34 29.996c-0.001 0.001-0.002 0.003-0.004 0.004h-27.993c-0.001-0.001-0.003-0.002-0.004-0.004v-23.993c0.001-0.001 0.002-0.003 0.004-0.004h27.993c0.001 0.001 0.003 0.002 0.004 0.004v23.993z"></path>
                          <path d="M30 11c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"></path>
                          <path d="M32 28h-24v-4l7-12 8 10h2l7-6z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-3" viewBox="0 0 40 40">
                          <path d="M15 4c-1.583 0-3.112 0.248-4.543 0.738-1.341 0.459-2.535 1.107-3.547 1.926-1.876 1.518-2.91 3.463-2.91 5.474 0 1.125 0.315 2.217 0.935 3.247 0.646 1.073 1.622 2.056 2.821 2.842 0.951 0.624 1.592 1.623 1.761 2.748 0.028 0.187 0.051 0.375 0.068 0.564 0.085-0.079 0.169-0.16 0.254-0.244 0.754-0.751 1.771-1.166 2.823-1.166 0.167 0 0.335 0.011 0.503 0.032 0.605 0.077 1.223 0.116 1.836 0.116 1.583 0 3.112-0.248 4.543-0.738 1.341-0.459 2.535-1.107 3.547-1.926 1.876-1.518 2.91-3.463 2.91-5.474s-1.033-3.956-2.91-5.474c-1.012-0.819-2.206-1.467-3.547-1.926-1.431-0.49-2.96-0.738-4.543-0.738zM15 0v0c8.284 0 15 5.435 15 12.139s-6.716 12.139-15 12.139c-0.796 0-1.576-0.051-2.339-0.147-3.222 3.209-6.943 3.785-10.661 3.869v-0.785c2.008-0.98 3.625-2.765 3.625-4.804 0-0.285-0.022-0.564-0.063-0.837-3.392-2.225-5.562-5.625-5.562-9.434 0-6.704 6.716-12.139 15-12.139zM31.125 27.209c0 1.748 1.135 3.278 2.875 4.118v0.673c-3.223-0.072-6.181-0.566-8.973-3.316-0.661 0.083-1.337 0.126-2.027 0.126-2.983 0-5.732-0.805-7.925-2.157 4.521-0.016 8.789-1.464 12.026-4.084 1.631-1.32 2.919-2.87 3.825-4.605 0.961-1.84 1.449-3.799 1.449-5.825 0-0.326-0.014-0.651-0.039-0.974 2.268 1.873 3.664 4.426 3.664 7.24 0 3.265-1.88 6.179-4.82 8.086-0.036 0.234-0.055 0.474-0.055 0.718z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-4" viewBox="0 0 40 40">
                          <path d="M16 4c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14-6.268-14-14-14zM16 29.25c-6.213 0-11.25-5.037-11.25-11.25s5.037-11.25 11.25-11.25c6.213 0 11.25 5.037 11.25 11.25s-5.037 11.25-11.25 11.25zM29.212 8.974c0.501-0.877 0.788-1.892 0.788-2.974 0-3.314-2.686-6-6-6-1.932 0-3.65 0.913-4.747 2.331 4.121 0.851 7.663 3.287 9.96 6.643v0zM12.748 2.331c-1.097-1.418-2.816-2.331-4.748-2.331-3.314 0-6 2.686-6 6 0 1.082 0.287 2.098 0.788 2.974 2.297-3.356 5.838-5.792 9.96-6.643z"></path>
                          <path d="M16 18v-8h-2v10h8v-2z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-5" viewBox="0 0 40 40">
                          <path d="M26 30l6-16h-26l-6 16zM4 12l-4 18v-26h9l4 4h13v4z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-5" viewBox="0 0 40 40">
                          <path d="M28 4v26h-21c-1.657 0-3-1.343-3-3s1.343-3 3-3h19v-24h-20c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24v-28h-2z"></path>
                          <path d="M7.002 26v0c-0.001 0-0.001 0-0.002 0-0.552 0-1 0.448-1 1s0.448 1 1 1c0.001 0 0.001-0 0.002-0v0h18.997v-2h-18.997z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-6" viewBox="0 0 40 40">
                          <path d="M11.366 22.564l1.291-1.807-1.414-1.414-1.807 1.291c-0.335-0.187-0.694-0.337-1.071-0.444l-0.365-2.19h-2l-0.365 2.19c-0.377 0.107-0.736 0.256-1.071 0.444l-1.807-1.291-1.414 1.414 1.291 1.807c-0.187 0.335-0.337 0.694-0.443 1.071l-2.19 0.365v2l2.19 0.365c0.107 0.377 0.256 0.736 0.444 1.071l-1.291 1.807 1.414 1.414 1.807-1.291c0.335 0.187 0.694 0.337 1.071 0.444l0.365 2.19h2l0.365-2.19c0.377-0.107 0.736-0.256 1.071-0.444l1.807 1.291 1.414-1.414-1.291-1.807c0.187-0.335 0.337-0.694 0.444-1.071l2.19-0.365v-2l-2.19-0.365c-0.107-0.377-0.256-0.736-0.444-1.071zM7 27c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM32 12v-2l-2.106-0.383c-0.039-0.251-0.088-0.499-0.148-0.743l1.799-1.159-0.765-1.848-2.092 0.452c-0.132-0.216-0.273-0.426-0.422-0.629l1.219-1.761-1.414-1.414-1.761 1.219c-0.203-0.149-0.413-0.29-0.629-0.422l0.452-2.092-1.848-0.765-1.159 1.799c-0.244-0.059-0.492-0.109-0.743-0.148l-0.383-2.106h-2l-0.383 2.106c-0.251 0.039-0.499 0.088-0.743 0.148l-1.159-1.799-1.848 0.765 0.452 2.092c-0.216 0.132-0.426 0.273-0.629 0.422l-1.761-1.219-1.414 1.414 1.219 1.761c-0.149 0.203-0.29 0.413-0.422 0.629l-2.092-0.452-0.765 1.848 1.799 1.159c-0.059 0.244-0.109 0.492-0.148 0.743l-2.106 0.383v2l2.106 0.383c0.039 0.251 0.088 0.499 0.148 0.743l-1.799 1.159 0.765 1.848 2.092-0.452c0.132 0.216 0.273 0.426 0.422 0.629l-1.219 1.761 1.414 1.414 1.761-1.219c0.203 0.149 0.413 0.29 0.629 0.422l-0.452 2.092 1.848 0.765 1.159-1.799c0.244 0.059 0.492 0.109 0.743 0.148l0.383 2.106h2l0.383-2.106c0.251-0.039 0.499-0.088 0.743-0.148l1.159 1.799 1.848-0.765-0.452-2.092c0.216-0.132 0.426-0.273 0.629-0.422l1.761 1.219 1.414-1.414-1.219-1.761c0.149-0.203 0.29-0.413 0.422-0.629l2.092 0.452 0.765-1.848-1.799-1.159c0.059-0.244 0.109-0.492 0.148-0.743l2.106-0.383zM21 15.35c-2.402 0-4.35-1.948-4.35-4.35s1.948-4.35 4.35-4.35 4.35 1.948 4.35 4.35c0 2.402-1.948 4.35-4.35 4.35z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-7" viewBox="0 0 40 40">
                          <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM3 16c0-7.18 5.82-13 13-13 3.424 0 6.538 1.325 8.86 3.488l-12.86 5.512-5.512 12.86c-2.164-2.322-3.488-5.436-3.488-8.86zM18.286 18.286l-8.003 3.43 3.43-8.003 4.573 4.573zM16 29c-3.424 0-6.539-1.325-8.86-3.488l12.86-5.512 5.512-12.86c2.164 2.322 3.488 5.436 3.488 8.86 0 7.18-5.82 13-13 13z"></path>
                        </symbol>

                        <symbol className="icon icon-" id="icon-8" viewBox="0 0 40 40">
                          <path d="M30.478 18.061c0.088-0.65 0.138-1.311 0.138-1.983 0-8.072-6.579-14.617-14.694-14.617-0.806 0-1.597 0.066-2.367 0.192-1.38-0.899-3.021-1.423-4.788-1.423-4.845 0-8.768 3.925-8.768 8.769 0 1.762 0.519 3.4 1.412 4.774-0.12 0.751-0.182 1.52-0.182 2.304 0 8.073 6.576 14.618 14.693 14.618 0.916 0 1.808-0.089 2.675-0.246 1.347 0.836 2.933 1.322 4.634 1.322 4.844 0 8.768-3.926 8.768-8.77-0-1.831-0.56-3.533-1.522-4.94zM17.296 26.85c-4.669 0.243-6.854-0.79-8.856-2.668-2.235-2.098-1.337-4.491 0.484-4.613 1.82-0.122 2.913 2.063 3.884 2.671 0.97 0.605 4.662 1.985 6.612-0.244 2.123-2.427-1.412-3.683-4.004-4.063-3.7-0.548-8.372-2.55-8.008-6.494 0.364-3.942 3.349-5.963 6.491-6.248 4.005-0.364 6.612 0.607 8.674 2.366 2.384 2.033 1.094 4.306-0.425 4.489-1.513 0.182-3.213-3.344-6.549-3.397-3.439-0.054-5.764 3.579-1.518 4.612 4.248 1.032 8.797 1.455 10.435 5.339 1.639 3.884-2.547 8.008-7.22 8.251z"></path>
                        </symbol>
                      </g>
                      <g
                        id="itemsContainer"
                        className="d-none"
                        data-svg-origin="250 250"
                        transform="matrix(0.38734,-0.92193,0.92193,0.38734,-77.3193578290572,383.64893561257276)"
                      >
                        <a
                          className="item"
                          id="item-1"
                          transform="matrix(1,0,0,1,0,0)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-2"
                          transform="matrix(0.7071,-0.7071,0.7071,0.7071,-103.55339059327378,249.99999999999997)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-3"
                          transform="matrix(0,-1,1,0,0,500)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-4"
                          transform="matrix(-0.7071,-0.7071,0.7071,-0.7071,249.99999999999997,603.5533905932738)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-5"
                          transform="matrix(-1,0,0,-1,500,500)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-6"
                          transform="matrix(-0.7071,0.7071,-0.7071,-0.7071,603.5533905932738,250.00000000000006)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-7"
                          transform="matrix(0,1,-1,0,500.00000000000006,0)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                        <a
                          className="item"
                          id="item-8"
                          transform="matrix(0.7071,0.7071,-0.7071,0.7071,250.00000000000009,-103.55339059327378)"
                          data-svg-origin="250 250"
                        >
                          <path
                            fill="none"
                            stroke="#111"
                            d="M350,250 l150,0 A250,250 0 0,0 426.7766952966369,73.22330470336314 l-106.06601717798213,106.0660171779821 A100,100 0 0,1 350,250"
                            className="sector"
                          ></path>
                          <use
                            width="40"
                            height="40"
                            x="394.4505615234375"
                            y="161.88233947753906"
                            transform="rotate(67.5 414.4505615234375 181.88233947753906)"
                          ></use>
                        </a>
                      </g>
                      <g id="trigger" className="trigger menu-trigger">
                        <circle cx="250" cy="250" r="40"></circle>
                        <text
                          id="label"
                          textAnchor="middle"
                          x="250"
                          y="255"
                          fill="#fff"
                          fontSize="1.5em"
                        >
                          -
                        </text>
                      </g>
                    </svg>
                  </div>
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 982.423 883.5"
                    preserveAspectRatio="none"
                  >
                    <defs>
                      <clipPath id="clipPath">
                        <rect width="290.368" height="16" fill="none" />
                      </clipPath>
                    </defs>
                    <g
                      id="Rectangle_2"
                      data-name="Rectangle 2"
                      transform="translate(10.923 10)"
                      fill="none"
                      stroke="#656565"
                      strokeWidth="1"
                    >
                      <rect width="960" height="864" stroke="none" />
                      <rect x="0.5" y="0.5" width="959" height="863" fill="none" />
                    </g>
                    <g
                      id="Group_3"
                      data-name="Group 3"
                      transform="translate(-255.077 -13.9)"
                    >
                      <g
                        id="Group_1"
                        data-name="Group 1"
                        transform="translate(256.577 256.647)"
                      >
                        <path
                          id="Path_9"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                      <g
                        id="Group_2"
                        data-name="Group 2"
                        transform="translate(256.577 649.153)"
                      >
                        <path
                          id="Path_9-2"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-2"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_5"
                      data-name="Group 5"
                      transform="translate(946.823 -255.077) rotate(90)"
                    >
                      <g
                        id="Group_1-2"
                        data-name="Group 1"
                        transform="translate(256.577 256.647)"
                      >
                        <path
                          id="Path_9-3"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-3"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                      <g
                        id="Group_2-2"
                        data-name="Group 2"
                        transform="translate(256.577 649.153)"
                      >
                        <path
                          id="Path_9-4"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-4"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_6"
                      data-name="Group 6"
                      transform="translate(946.823 596.434) rotate(90)"
                    >
                      <g
                        id="Group_1-3"
                        data-name="Group 1"
                        transform="translate(256.577 256.647)"
                      >
                        <path
                          id="Path_9-5"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-5"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                      <g
                        id="Group_2-3"
                        data-name="Group 2"
                        transform="translate(256.577 649.153)"
                      >
                        <path
                          id="Path_9-6"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-6"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                    </g>
                    <g
                      id="Group_4"
                      data-name="Group 4"
                      transform="translate(695.357 -13.9)"
                    >
                      <g
                        id="Group_1-4"
                        data-name="Group 1"
                        transform="translate(256.577 256.647)"
                      >
                        <path
                          id="Path_9-7"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-7"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                      <g
                        id="Group_2-4"
                        data-name="Group 2"
                        transform="translate(256.577 649.153)"
                      >
                        <path
                          id="Path_9-8"
                          data-name="Path 9"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -356.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                        <path
                          id="Path_10-8"
                          data-name="Path 10"
                          d="M167.5,356.647h28.989"
                          transform="translate(-167.5 -350.647)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="3"
                        />
                      </g>
                    </g>
                    <path
                      id="Path_11"
                      data-name="Path 11"
                      d="M103.577,101.482v-35h35"
                      transform="translate(-102.077 -64.982)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      d="M138.577,101.482v-35h-35"
                      transform="translate(842.346 -64.982)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <path
                      id="Path_13"
                      data-name="Path 13"
                      d="M138.577,66.482v35h-35"
                      transform="translate(842.346 780.518)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <path
                      id="Path_14"
                      data-name="Path 14"
                      d="M103.577,66.482v35h35"
                      transform="translate(-102.077 780.518)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="3"
                    />
                    <g
                      id="Group_7"
                      data-name="Group 7"
                      transform="translate(-262.965 -261.693)"
                    >
                      <line
                        id="Line_11"
                        data-name="Line 11"
                        y2="811.991"
                        transform="translate(753.64 297.697)"
                        fill="none"
                        stroke="#cecece"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_14"
                        data-name="Line 14"
                        y2="811.776"
                        transform="translate(1159.776 703.8) rotate(90)"
                        fill="none"
                        stroke="#cecece"
                        strokeWidth="2"
                      />
                      <line
                        id="Line_12"
                        data-name="Line 12"
                        y2="811.776"
                        transform="translate(1040.894 416.794) rotate(45)"
                        fill="none"
                        stroke="#cecece"
                        strokeWidth="1"
                      />
                      <line
                        id="Line_13"
                        data-name="Line 13"
                        y2="811.776"
                        transform="translate(466.882 416.794) rotate(-45)"
                        fill="none"
                        stroke="#cecece"
                        strokeWidth="1"
                      />
                    </g>
                    <g
                      id="Repeat_Grid_5"
                      data-name="Repeat Grid 5"
                      transform="translate(345.739 14.489)"
                      clipPath="url(#clipPath)"
                    >
                      <g transform="translate(-238.5 -30.5)">
                        <line
                          id="Line_10"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-231.5 -30.5)">
                        <line
                          id="Line_10-2"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-224.5 -30.5)">
                        <line
                          id="Line_10-3"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-217.5 -30.5)">
                        <line
                          id="Line_10-4"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-210.5 -30.5)">
                        <line
                          id="Line_10-5"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-203.5 -30.5)">
                        <line
                          id="Line_10-6"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-196.5 -30.5)">
                        <line
                          id="Line_10-7"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-189.5 -30.5)">
                        <line
                          id="Line_10-8"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-182.5 -30.5)">
                        <line
                          id="Line_10-9"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-175.5 -30.5)">
                        <line
                          id="Line_10-10"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-168.5 -30.5)">
                        <line
                          id="Line_10-11"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-161.5 -30.5)">
                        <line
                          id="Line_10-12"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-154.5 -30.5)">
                        <line
                          id="Line_10-13"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-147.5 -30.5)">
                        <line
                          id="Line_10-14"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-140.5 -30.5)">
                        <line
                          id="Line_10-15"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-133.5 -30.5)">
                        <line
                          id="Line_10-16"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-126.5 -30.5)">
                        <line
                          id="Line_10-17"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-119.5 -30.5)">
                        <line
                          id="Line_10-18"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-112.5 -30.5)">
                        <line
                          id="Line_10-19"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-105.5 -30.5)">
                        <line
                          id="Line_10-20"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-98.5 -30.5)">
                        <line
                          id="Line_10-21"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-91.5 -30.5)">
                        <line
                          id="Line_10-22"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-84.5 -30.5)">
                        <line
                          id="Line_10-23"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-77.5 -30.5)">
                        <line
                          id="Line_10-24"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-70.5 -30.5)">
                        <line
                          id="Line_10-25"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-63.5 -30.5)">
                        <line
                          id="Line_10-26"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-56.5 -30.5)">
                        <line
                          id="Line_10-27"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-49.5 -30.5)">
                        <line
                          id="Line_10-28"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-42.5 -30.5)">
                        <line
                          id="Line_10-29"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-35.5 -30.5)">
                        <line
                          id="Line_10-30"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-28.5 -30.5)">
                        <line
                          id="Line_10-31"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-21.5 -30.5)">
                        <line
                          id="Line_10-32"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-14.5 -30.5)">
                        <line
                          id="Line_10-33"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-7.5 -30.5)">
                        <line
                          id="Line_10-34"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-0.5 -30.5)">
                        <line
                          id="Line_10-35"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(6.5 -30.5)">
                        <line
                          id="Line_10-36"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(13.5 -30.5)">
                        <line
                          id="Line_10-37"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(20.5 -30.5)">
                        <line
                          id="Line_10-38"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(27.5 -30.5)">
                        <line
                          id="Line_10-39"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(34.5 -30.5)">
                        <line
                          id="Line_10-40"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(41.5 -30.5)">
                        <line
                          id="Line_10-41"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(48.5 -30.5)">
                        <line
                          id="Line_10-42"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                    <g
                      id="Repeat_Grid_6"
                      data-name="Repeat Grid 6"
                      transform="translate(345.739 853.011)"
                      clipPath="url(#clipPath)"
                    >
                      <g transform="translate(-238.5 -30.5)">
                        <line
                          id="Line_10-43"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-231.5 -30.5)">
                        <line
                          id="Line_10-44"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-224.5 -30.5)">
                        <line
                          id="Line_10-45"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-217.5 -30.5)">
                        <line
                          id="Line_10-46"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-210.5 -30.5)">
                        <line
                          id="Line_10-47"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-203.5 -30.5)">
                        <line
                          id="Line_10-48"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-196.5 -30.5)">
                        <line
                          id="Line_10-49"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-189.5 -30.5)">
                        <line
                          id="Line_10-50"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-182.5 -30.5)">
                        <line
                          id="Line_10-51"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-175.5 -30.5)">
                        <line
                          id="Line_10-52"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-168.5 -30.5)">
                        <line
                          id="Line_10-53"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-161.5 -30.5)">
                        <line
                          id="Line_10-54"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-154.5 -30.5)">
                        <line
                          id="Line_10-55"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-147.5 -30.5)">
                        <line
                          id="Line_10-56"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-140.5 -30.5)">
                        <line
                          id="Line_10-57"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-133.5 -30.5)">
                        <line
                          id="Line_10-58"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-126.5 -30.5)">
                        <line
                          id="Line_10-59"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-119.5 -30.5)">
                        <line
                          id="Line_10-60"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-112.5 -30.5)">
                        <line
                          id="Line_10-61"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-105.5 -30.5)">
                        <line
                          id="Line_10-62"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-98.5 -30.5)">
                        <line
                          id="Line_10-63"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-91.5 -30.5)">
                        <line
                          id="Line_10-64"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-84.5 -30.5)">
                        <line
                          id="Line_10-65"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-77.5 -30.5)">
                        <line
                          id="Line_10-66"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-70.5 -30.5)">
                        <line
                          id="Line_10-67"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-63.5 -30.5)">
                        <line
                          id="Line_10-68"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-56.5 -30.5)">
                        <line
                          id="Line_10-69"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-49.5 -30.5)">
                        <line
                          id="Line_10-70"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-42.5 -30.5)">
                        <line
                          id="Line_10-71"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-35.5 -30.5)">
                        <line
                          id="Line_10-72"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-28.5 -30.5)">
                        <line
                          id="Line_10-73"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-21.5 -30.5)">
                        <line
                          id="Line_10-74"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-14.5 -30.5)">
                        <line
                          id="Line_10-75"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-7.5 -30.5)">
                        <line
                          id="Line_10-76"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-0.5 -30.5)">
                        <line
                          id="Line_10-77"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(6.5 -30.5)">
                        <line
                          id="Line_10-78"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(13.5 -30.5)">
                        <line
                          id="Line_10-79"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(20.5 -30.5)">
                        <line
                          id="Line_10-80"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(27.5 -30.5)">
                        <line
                          id="Line_10-81"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(34.5 -30.5)">
                        <line
                          id="Line_10-82"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(41.5 -30.5)">
                        <line
                          id="Line_10-83"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(48.5 -30.5)">
                        <line
                          id="Line_10-84"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                    <g
                      id="Repeat_Grid_7"
                      data-name="Repeat Grid 7"
                      transform="translate(30.489 296.816) rotate(90)"
                      clipPath="url(#clipPath)"
                    >
                      <g transform="translate(-238.5 -30.5)">
                        <line
                          id="Line_10-85"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-231.5 -30.5)">
                        <line
                          id="Line_10-86"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-224.5 -30.5)">
                        <line
                          id="Line_10-87"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-217.5 -30.5)">
                        <line
                          id="Line_10-88"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-210.5 -30.5)">
                        <line
                          id="Line_10-89"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-203.5 -30.5)">
                        <line
                          id="Line_10-90"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-196.5 -30.5)">
                        <line
                          id="Line_10-91"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-189.5 -30.5)">
                        <line
                          id="Line_10-92"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-182.5 -30.5)">
                        <line
                          id="Line_10-93"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-175.5 -30.5)">
                        <line
                          id="Line_10-94"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-168.5 -30.5)">
                        <line
                          id="Line_10-95"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-161.5 -30.5)">
                        <line
                          id="Line_10-96"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-154.5 -30.5)">
                        <line
                          id="Line_10-97"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-147.5 -30.5)">
                        <line
                          id="Line_10-98"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-140.5 -30.5)">
                        <line
                          id="Line_10-99"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-133.5 -30.5)">
                        <line
                          id="Line_10-100"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-126.5 -30.5)">
                        <line
                          id="Line_10-101"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-119.5 -30.5)">
                        <line
                          id="Line_10-102"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-112.5 -30.5)">
                        <line
                          id="Line_10-103"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-105.5 -30.5)">
                        <line
                          id="Line_10-104"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-98.5 -30.5)">
                        <line
                          id="Line_10-105"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-91.5 -30.5)">
                        <line
                          id="Line_10-106"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-84.5 -30.5)">
                        <line
                          id="Line_10-107"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-77.5 -30.5)">
                        <line
                          id="Line_10-108"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-70.5 -30.5)">
                        <line
                          id="Line_10-109"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-63.5 -30.5)">
                        <line
                          id="Line_10-110"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-56.5 -30.5)">
                        <line
                          id="Line_10-111"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-49.5 -30.5)">
                        <line
                          id="Line_10-112"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-42.5 -30.5)">
                        <line
                          id="Line_10-113"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-35.5 -30.5)">
                        <line
                          id="Line_10-114"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-28.5 -30.5)">
                        <line
                          id="Line_10-115"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-21.5 -30.5)">
                        <line
                          id="Line_10-116"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-14.5 -30.5)">
                        <line
                          id="Line_10-117"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-7.5 -30.5)">
                        <line
                          id="Line_10-118"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-0.5 -30.5)">
                        <line
                          id="Line_10-119"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(6.5 -30.5)">
                        <line
                          id="Line_10-120"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(13.5 -30.5)">
                        <line
                          id="Line_10-121"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(20.5 -30.5)">
                        <line
                          id="Line_10-122"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(27.5 -30.5)">
                        <line
                          id="Line_10-123"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(34.5 -30.5)">
                        <line
                          id="Line_10-124"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(41.5 -30.5)">
                        <line
                          id="Line_10-125"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(48.5 -30.5)">
                        <line
                          id="Line_10-126"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                    <g
                      id="Repeat_Grid_8"
                      data-name="Repeat Grid 8"
                      transform="translate(966.428 296.816) rotate(90)"
                      clipPath="url(#clipPath)"
                    >
                      <g transform="translate(-238.5 -30.5)">
                        <line
                          id="Line_10-127"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-231.5 -30.5)">
                        <line
                          id="Line_10-128"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-224.5 -30.5)">
                        <line
                          id="Line_10-129"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-217.5 -30.5)">
                        <line
                          id="Line_10-130"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-210.5 -30.5)">
                        <line
                          id="Line_10-131"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-203.5 -30.5)">
                        <line
                          id="Line_10-132"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-196.5 -30.5)">
                        <line
                          id="Line_10-133"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-189.5 -30.5)">
                        <line
                          id="Line_10-134"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-182.5 -30.5)">
                        <line
                          id="Line_10-135"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-175.5 -30.5)">
                        <line
                          id="Line_10-136"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-168.5 -30.5)">
                        <line
                          id="Line_10-137"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-161.5 -30.5)">
                        <line
                          id="Line_10-138"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-154.5 -30.5)">
                        <line
                          id="Line_10-139"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-147.5 -30.5)">
                        <line
                          id="Line_10-140"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-140.5 -30.5)">
                        <line
                          id="Line_10-141"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-133.5 -30.5)">
                        <line
                          id="Line_10-142"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-126.5 -30.5)">
                        <line
                          id="Line_10-143"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-119.5 -30.5)">
                        <line
                          id="Line_10-144"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-112.5 -30.5)">
                        <line
                          id="Line_10-145"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-105.5 -30.5)">
                        <line
                          id="Line_10-146"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-98.5 -30.5)">
                        <line
                          id="Line_10-147"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-91.5 -30.5)">
                        <line
                          id="Line_10-148"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-84.5 -30.5)">
                        <line
                          id="Line_10-149"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-77.5 -30.5)">
                        <line
                          id="Line_10-150"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-70.5 -30.5)">
                        <line
                          id="Line_10-151"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-63.5 -30.5)">
                        <line
                          id="Line_10-152"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-56.5 -30.5)">
                        <line
                          id="Line_10-153"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-49.5 -30.5)">
                        <line
                          id="Line_10-154"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-42.5 -30.5)">
                        <line
                          id="Line_10-155"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-35.5 -30.5)">
                        <line
                          id="Line_10-156"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-28.5 -30.5)">
                        <line
                          id="Line_10-157"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-21.5 -30.5)">
                        <line
                          id="Line_10-158"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-14.5 -30.5)">
                        <line
                          id="Line_10-159"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-7.5 -30.5)">
                        <line
                          id="Line_10-160"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(-0.5 -30.5)">
                        <line
                          id="Line_10-161"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(6.5 -30.5)">
                        <line
                          id="Line_10-162"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(13.5 -30.5)">
                        <line
                          id="Line_10-163"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(20.5 -30.5)">
                        <line
                          id="Line_10-164"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(27.5 -30.5)">
                        <line
                          id="Line_10-165"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(34.5 -30.5)">
                        <line
                          id="Line_10-166"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(41.5 -30.5)">
                        <line
                          id="Line_10-167"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                      <g transform="translate(48.5 -30.5)">
                        <line
                          id="Line_10-168"
                          data-name="Line 10"
                          y2="16"
                          transform="translate(239.5 30.5)"
                          fill="none"
                          stroke="#b4b4b4"
                          strokeWidth="2"
                        />
                      </g>
                    </g>

                    <rect
                      id="Rectangle_12"
                      data-name="Rectangle 12"
                      width="63"
                      height="90.511"
                      rx="20"
                      transform="translate(880.389 770.5)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <rect
                      id="Rectangle_13"
                      data-name="Rectangle 13"
                      width="50"
                      height="72.511"
                      rx="20"
                      transform="translate(886.889 779.5)"
                      fill="rgba(69,69,69,0.45)"
                    />

                    <rect
                      id="Rectangle_11"
                      data-name="Rectangle 11"
                      width="63"
                      height="31"
                      rx="15.5"
                      transform="translate(879.923 726.5)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <rect
                      id="Rectangle_5"
                      data-name="Rectangle 5"
                      width="63"
                      height="31"
                      rx="15.5"
                      transform="translate(42.923 752)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <rect
                      id="Rectangle_10"
                      data-name="Rectangle 10"
                      width="63"
                      height="31"
                      rx="15.5"
                      transform="translate(879.923 682.5)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <rect
                      id="Rectangle_6"
                      data-name="Rectangle 6"
                      width="63"
                      height="31"
                      rx="15.5"
                      transform="translate(42.923 711)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <rect
                      id="Rectangle_7"
                      data-name="Rectangle 7"
                      width="63"
                      height="31"
                      rx="15.5"
                      transform="translate(42.923 667)"
                      fill="rgba(69,69,69,0.45)"
                    />
                    <path
                      id="Path_15"
                      data-name="Path 15"
                      d="M255,744.5l10.271,16.407v185.67"
                      transform="translate(-149.077 -85.566)"
                      fill="none"
                      stroke="#838383"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_18"
                      data-name="Path 18"
                      d="M265.271,744.5,255,760.907v185.67"
                      transform="translate(615.117 -86.066)"
                      fill="none"
                      stroke="#838383"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_16"
                      data-name="Path 16"
                      d="M255,744.5l10.271,16.407v185.67"
                      transform="translate(-139.654 -93.566)"
                      fill="none"
                      stroke="#838383"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_19"
                      data-name="Path 19"
                      d="M265.271,744.5,255,760.907v185.67"
                      transform="translate(605.694 -94.066)"
                      fill="none"
                      stroke="#838383"
                      strokeWidth="2"
                    />
                    <path
                      id="Union_1"
                      data-name="Union 1"
                      d="M197.767,198.169v-.242l.121.121L337.816,58.082,197.888,198.049h0L337.816,338.015,197.888,198.049h0l-.121-.121v.242Zm0,0L57.96,338.015Zm0,0,.121-.121h0Zm.121-.121h0ZM0,198.049H0ZM57.96,58.082,197.767,197.927Z"
                      transform="translate(293.035 244.004)"
                      fill="none"
                      stroke="#7b7b7b"
                      strokeWidth="1"
                    />
                    <path
                      id="Union_2"
                      data-name="Union 2"
                      d="M119.814,120.1v-.148l.074.074L204.662,35.2l-84.774,84.828h0l84.774,84.828-84.774-84.828-.074-.074v.148Zm0,0-84.7,84.754Zm0,0,.074-.074h0ZM0,120.028H0ZM35.114,35.2l84.7,84.754Z"
                      transform="translate(371.035 322.004)"
                      fill="none"
                      stroke="#000"
                      strokeWidth="1"
                    />
                  </svg>
                </div>
                <div className="box-6 box">
                  <div className="box-6-svg">
                    <svg
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 54.526 349.464"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect width="307.992" height="9" fill="none" />
                        </clipPath>
                      </defs>
                      <g
                        id="Repeat_Grid_17"
                        data-name="Repeat Grid 17"
                        transform="translate(9 30.727) rotate(90)"
                        clipPath="url(#clipPath)"
                      >
                        <g transform="translate(-74 -81)">
                          <g
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-38 -81)">
                          <g
                            id="Rectangle_14-2"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-2 -81)">
                          <g
                            id="Rectangle_14-3"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(34 -81)">
                          <g
                            id="Rectangle_14-4"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(70 -81)">
                          <g
                            id="Rectangle_14-5"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(106 -81)">
                          <g
                            id="Rectangle_14-6"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(142 -81)">
                          <g
                            id="Rectangle_14-7"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(178 -81)">
                          <g
                            id="Rectangle_14-8"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(214 -81)">
                          <g
                            id="Rectangle_14-9"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(7,7,7,0.47)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                      </g>
                      <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M4179.589,66,4204.7,92.843h299.127L4527.574,66"
                        transform="translate(119.843 -4178.858) rotate(90)"
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                      />
                    </svg>
                  </div>
                </div>
                <div className="box-7 box"></div>
                <div className="box-8 box">
                  <div className="data-wrap">
                    <div className="data">
                      <div className="data__age singel__data_div">
                        Age
                        <span className="age data_result">25-32</span>
                      </div>
                      <div className="data__emotion singel__data_div">
                        Emotion
                        <span className="emotion data_result">Happy</span>
                      </div>
                      <div className="data__gender singel__data_div">
                        Gender
                        <span className="gender data_result">Male</span>
                      </div>
                      <div className="data__admin singel__data_div">
                        Admin
                        <span className="is_admin data_result">Yes</span>
                      </div>
                      <div className="data__time singel__data_div">
                        Time
                        <span className="time data_result">17:23:00</span>
                      </div>
                    </div>
                  </div>
                  <div className="circle-down-menu">
                    <div
                      onClick={() => {
                        console.log(22)
                        history.push('/menu')
                      }}
                      className="cir"
                    >
                      <KeyboardArrowDownIcon />
                    </div>
                  </div>
                  <div className="box-8-svg">
                    <svg
                      width="100%"
                      height="100%"
                      preserveAspectRatio="none"
                      viewBox="0 0 912.042 56.119"
                    >
                      <defs>
                        <clipPath id="clipPath">
                          <rect width="488" height="9" fill="none" />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_18"
                        data-name="Group 18"
                        transform="translate(-218.958 -870.382)"
                      >
                        <path
                          id="Path_37"
                          data-name="Path 37"
                          d="M4341.613,968.69H3913.958V944.571"
                          transform="translate(-3694 -43.19)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_38"
                          data-name="Path 38"
                          d="M3913.958,968.69h426.822V944.571"
                          transform="translate(-3210.779 -43.19)"
                          fill="none"
                          stroke="#000"
                          strokeWidth="2"
                        />
                      </g>
                      <g
                        id="Repeat_Grid_11"
                        data-name="Repeat Grid 11"
                        transform="translate(212.021)"
                        clipPath="url(#clipPath)"
                      >
                        <g transform="translate(-74 -81)">
                          <g
                            id="Rectangle_14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-62 -81)">
                          <g
                            id="Rectangle_14-2"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-50 -81)">
                          <g
                            id="Rectangle_14-3"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-38 -81)">
                          <g
                            id="Rectangle_14-4"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-26 -81)">
                          <g
                            id="Rectangle_14-5"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-14 -81)">
                          <g
                            id="Rectangle_14-6"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(-2 -81)">
                          <g
                            id="Rectangle_14-7"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(10 -81)">
                          <g
                            id="Rectangle_14-8"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(22 -81)">
                          <g
                            id="Rectangle_14-9"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(34 -81)">
                          <g
                            id="Rectangle_14-10"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(46 -81)">
                          <g
                            id="Rectangle_14-11"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(58 -81)">
                          <g
                            id="Rectangle_14-12"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(70 -81)">
                          <g
                            id="Rectangle_14-13"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(82 -81)">
                          <g
                            id="Rectangle_14-14"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(94 -81)">
                          <g
                            id="Rectangle_14-15"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(106 -81)">
                          <g
                            id="Rectangle_14-16"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(118 -81)">
                          <g
                            id="Rectangle_14-17"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(130 -81)">
                          <g
                            id="Rectangle_14-18"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(142 -81)">
                          <g
                            id="Rectangle_14-19"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(154 -81)">
                          <g
                            id="Rectangle_14-20"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(166 -81)">
                          <g
                            id="Rectangle_14-21"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(178 -81)">
                          <g
                            id="Rectangle_14-22"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(190 -81)">
                          <g
                            id="Rectangle_14-23"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(202 -81)">
                          <g
                            id="Rectangle_14-24"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(214 -81)">
                          <g
                            id="Rectangle_14-25"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(226 -81)">
                          <g
                            id="Rectangle_14-26"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(238 -81)">
                          <g
                            id="Rectangle_14-27"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(250 -81)">
                          <g
                            id="Rectangle_14-28"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(262 -81)">
                          <g
                            id="Rectangle_14-29"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(274 -81)">
                          <g
                            id="Rectangle_14-30"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(286 -81)">
                          <g
                            id="Rectangle_14-31"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(298 -81)">
                          <g
                            id="Rectangle_14-32"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(310 -81)">
                          <g
                            id="Rectangle_14-33"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(322 -81)">
                          <g
                            id="Rectangle_14-34"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(334 -81)">
                          <g
                            id="Rectangle_14-35"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(346 -81)">
                          <g
                            id="Rectangle_14-36"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(358 -81)">
                          <g
                            id="Rectangle_14-37"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(370 -81)">
                          <g
                            id="Rectangle_14-38"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(382 -81)">
                          <g
                            id="Rectangle_14-39"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(394 -81)">
                          <g
                            id="Rectangle_14-40"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                        <g transform="translate(406 -81)">
                          <g
                            id="Rectangle_14-41"
                            data-name="Rectangle 14"
                            transform="translate(74 81)"
                            fill="rgba(44,44,44,0.8)"
                            stroke="#707070"
                            strokeWidth="1"
                          >
                            <rect width="5" height="9" rx="2.5" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="4"
                              height="8"
                              rx="2"
                              fill="none"
                            />
                          </g>
                        </g>
                      </g>
                      <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M3925.8,996.012h300.3l15-11.927h100.363"
                        transform="translate(-3913.979 -948)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M4341.462,996.012h-300.3l-15-11.927H3925.8"
                        transform="translate(-3440.242 -948)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="1"
                      />
                    </svg>
                  </div>
                </div>
                <div className="box-9 box"></div>
              </div>
            </div>
          </div>
          <div className="app-b">
            <Pad>
              <TextHeading3 $as="h4">Sensors</TextHeading3>
              <TextParagraph1>
                Temperature: <br/>
                Humidity: <br/>
                Air Pressure: <br/>
                Air Quality: <br/>
                Color: <br/>
              </TextParagraph1>
              <TextParagraph1>
                Functionality will be updated time by time.
              </TextParagraph1>
            </Pad>
          </div>
        </div>
      </div>
        </Secuence>
      </Main>
    );
  }
}

export default withStyles(styles)(Playground);
